"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.login_clx = exports.login_failed_clx = void 0;
const statements_1 = require("../statements");
const login_success = 'A successful login occured.';
const may_indicate_an_attack = 'Many of these within a short period may indicate an attack.';
function login_failed_clx(title, description) {
    return [
        // failed login: user in org. country not allowed. not allowed Region. not allowed IP.
        {
            severity: 'warning',
            title,
            description: `${description} ${statements_1.not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: true
            }
        },
        // failed login: user not in org. country not allowed. not allowed Region. not allowed IP.
        {
            severity: 'info',
            title,
            description: `${description} ${statements_1.user_not_in_org} ${statements_1.not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: false,
            }
        },
        // failed login: user not in org. fraudulent IP. country not allowed. not allowed IP.
        {
            severity: 'info',
            title,
            description: `${description} ${statements_1.user_not_in_org} ${statements_1.fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. fraudulent IP. country not allowed. allowed IP.
        {
            severity: 'info',
            title,
            description: `${description}  ${statements_1.user_not_in_org} ${statements_1.fraud_ip_in_ip}`,
            predicates: {
                isAllowedIP: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        {
            severity: 'info',
            title,
            description: `${description}  ${statements_1.user_not_in_org} ${statements_1.fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. fraudulent IP. in allowed countries
        {
            severity: 'info',
            title,
            description: `${description}  ${statements_1.user_not_in_org} ${statements_1.fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. fraudulent IP. in allowed asn
        {
            severity: 'info',
            title,
            description: `${description}  ${statements_1.user_not_in_org} ${statements_1.fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. country not allowed. not allowed IP
        {
            severity: 'info',
            title,
            description: `${description}  ${statements_1.user_not_in_org} ${statements_1.not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. not allowed countries. allowed IP.
        {
            severity: 'info',
            title,
            description: `${description}  ${statements_1.user_not_in_org} ${statements_1.allowed_ip}`,
            predicates: {
                isAllowedIP: true,
                isMicrosoftActivity: false,
                isFraudulentIP: false,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. not allowed countries. allowed IP.
        {
            severity: 'info',
            title,
            description: `${description}  ${statements_1.user_not_in_org} ${statements_1.allowed_asn}`,
            predicates: {
                isAllowedASN: true,
                isMicrosoftActivity: false,
                isFraudulentIP: false,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org.
        {
            severity: 'info',
            title,
            description: `${description} ${statements_1.user_not_in_org}`,
            predicates: {
                isUserMemberOfOrganisation: false,
            }
        },
        // failed login
        {
            severity: 'info',
            title,
            description: `${description}`
        }
    ];
}
exports.login_failed_clx = login_failed_clx;
function login_clx(title) {
    return [
        // successful login: fraudulent IP. user in org. country not allowed. ip not allowed. 
        {
            severity: 'critical',
            title,
            description: `${login_success} ${statements_1.fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true,
            }
        },
        // successful login: fraudulent IP. user not in org. country not allowed. ip not allowed. 
        {
            severity: 'info',
            title,
            description: `${login_success} ${statements_1.user_not_in_org} ${statements_1.fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false,
            }
        },
        // successful login: country not allowed. user in org. region not allowed. ip not allowed.
        {
            severity: 'danger',
            title,
            description: `${login_success} ${statements_1.not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: true,
            }
        },
        // successful login: country not allowed. user not in org. region not allowed. ip not allowed.
        {
            severity: 'info',
            title,
            description: `${login_success} ${statements_1.user_not_in_org} ${statements_1.not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: false,
            }
        },
        // successful login: fraudulent IP. user in org. country not allowed. ip allowed. 
        {
            severity: 'warning',
            title,
            description: `${login_success} ${statements_1.fraud_ip_in_ip}`,
            predicates: {
                isAllowedIP: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true
            }
        },
        // successful login: fraudulent IP. user not in org. country not allowed. ip allowed. 
        {
            severity: 'info',
            title,
            description: `${login_success} ${statements_1.user_not_in_org} ${statements_1.fraud_ip_in_ip}`,
            predicates: {
                isAllowedIP: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // successful login: fraudulent IP. user in org. in allowed region.
        {
            severity: 'warning',
            title,
            description: `${login_success} ${statements_1.fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true
            }
        },
        // successful login: fraudulent IP. user not in org. in allowed region.
        {
            severity: 'info',
            title,
            description: `${login_success} ${statements_1.user_not_in_org} ${statements_1.fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // successful login: fraudulent IP. user in org. in allowed ASN
        {
            severity: 'warning',
            title,
            description: `${login_success} ${statements_1.fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true
            }
        },
        // successful login: fraudulent IP. user not in org. in allowed ASN
        {
            severity: 'info',
            title,
            description: `${login_success} ${statements_1.user_not_in_org} ${statements_1.fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // successful login: fraudulent IP. user in org. in allowed countries
        {
            severity: 'warning',
            title,
            description: `${login_success} ${statements_1.fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true
            }
        },
        // successful login: fraudulent IP. user not in org. in allowed countries
        {
            severity: 'info',
            title,
            description: `${login_success} ${statements_1.user_not_in_org} ${statements_1.fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // successful login
        {
            severity: 'info',
            title,
            description: `${login_success}`
        }
    ];
}
exports.login_clx = login_clx;
const UserLoggedIn = {
    info: {
        title: 'User Login',
        description: 'A user login event occured.',
    },
    classifications: login_clx('User Login')
};
const UserLoginFailed = {
    info: {
        title: 'User Login Failed',
        description: 'A failed login event occured.',
    },
    // extractor: (log: any) => {
    //     return {
    //         LogonError: !!log.LogonError? log.LogonError : null
    //     }
    // },
    description_extractor: (log) => {
        return {
            LogonError: !!log.LogonError ? log.LogonError : null
        };
    },
    classifications: login_failed_clx('User Login Failed', 'A failed login occured.')
};
const operations = {
    UserLoggedIn,
    UserLoginFailed
};
exports.default = operations;
