"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.user_not_in_org = exports.is_microsoft_activity = exports.fraud_ip_in_asn = exports.fraud_ip_in_ip = exports.fraud_ip_in_region = exports.fraud_ip_not_in_allowed_list = exports.allowed_asn = exports.allowed_ip = exports.allowed_region = exports.not_in_allowed_country = exports.not_in_allowed_ip = exports.fraud_ip_in_country = exports.fraud_ip = void 0;
exports.fraud_ip = 'It originated from a fraudulent IP.';
exports.fraud_ip_in_country = 'It originated from a fraudulent IP that is in your allowed countries.';
exports.not_in_allowed_ip = 'It originated from an IP that is not in your allowed IPs.';
exports.not_in_allowed_country = 'It originated from an IP that is not in your allowed countries.';
exports.allowed_region = 'It originated from an IP in your allowed region.';
exports.allowed_ip = 'It originated from an IP in your allowed IP list.';
exports.allowed_asn = 'It originated from an IP in your allowed ASN list.';
exports.fraud_ip_not_in_allowed_list = 'It originated from a fraudulent IP that not on your allowed list.';
exports.fraud_ip_in_region = 'It originated from a fraudulent IP that is in your allowed region.';
exports.fraud_ip_in_ip = 'It originated from a fraudulent IP that is in your allowed IP list.';
exports.fraud_ip_in_asn = 'It originated from a fraudulent IP that is in your allowed ASN list.';
exports.is_microsoft_activity = 'It is a Microsoft system operation event, with the IP address being the server handling the synchronization.';
exports.user_not_in_org = 'The account used is not part of your organisation.';
