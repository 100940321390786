"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const statements_1 = require("../statements");
const GetID = (log, key, type) => {
    if (log[key]) {
        let target = log[key].find((t) => t.Type === type);
        return target ? target.ID : undefined;
    }
};
const GetModifiedProperties = (log, name) => {
    if (log.ModifiedProperties) {
        let foundProperties = log.ModifiedProperties.find((p) => p.Name === name);
        switch (name) {
            case 'Role.DisplayName':
                let roleName = foundProperties.NewValue;
                return roleName;
            case 'ConsentAction.Permissions': {
                if (foundProperties !== undefined) {
                    let consentActionPremissions = foundProperties.NewValue;
                    let str = consentActionPremissions.substring(consentActionPremissions.lastIndexOf('[[') + 2, consentActionPremissions.lastIndexOf(']') - 1);
                    if (str.includes('=>')) {
                        return undefined;
                    }
                    let str_array = str.split('], [');
                    let obj_array = [];
                    str_array.forEach(s => {
                        var properties = s.split(', ');
                        var obj = {};
                        properties.forEach(p => {
                            var pair = p.split(': ');
                            obj[pair[0]] = pair[1];
                        });
                        obj_array.push(obj);
                    });
                    var result = obj_array.map(item => {
                        return ({ ResourceId: item.ResourceId, Scope: item.Scope });
                    });
                    return result;
                }
                else {
                    return undefined;
                }
            }
        }
    }
};
const app_consent_description = (fields) => {
    return `The following application '${fields.appName}' was consented.`;
};
function AppConsentClx(title) {
    return [
        // consent to application.
        {
            severity: 'danger',
            title,
            description: (fields) => app_consent_description(fields),
        }
    ];
}
const ConsentToApplication = {
    info: {
        title: 'Consent Granted For App',
        description: 'A User granted consent for an Azure App to access you tenant.',
    },
    description_extractor: (log) => {
        return {
            appName: GetID(log, 'Target', 1),
            user: GetID(log, 'Actor', 5),
            consentActionPremissions: GetModifiedProperties(log, 'ConsentAction.Permissions')
        };
    },
    classifications: AppConsentClx('Consent Granted For App')
};
const add_member_to_role_description = (fields) => {
    if (!!fields.user) {
        return `A User ${fields.user} was added to a Role Group ${fields.roleName}.`;
    }
    else {
        return `A Service Principal ${fields.service_principal} was added to a Role Group ${fields.roleName}.`;
    }
};
function AddMemberToRoleClx(title) {
    return [
        // add member to role: fraudulent IP. not in allowed list.
        {
            severity: 'critical',
            title,
            description: (fields) => add_member_to_role_description(fields) + statements_1.fraud_ip_not_in_allowed_list,
            predicates: {
                isFraudulentIP: true,
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false
            }
        },
        // add member to role: not in allowed list.
        {
            severity: 'danger',
            title,
            description: (fields) => add_member_to_role_description(fields) + statements_1.not_in_allowed_ip,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false
            }
        },
        // add member to role: fraud IP.
        {
            severity: 'warning',
            title,
            description: (fields) => add_member_to_role_description(fields) + statements_1.fraud_ip,
            predicates: {
                isFraudulentIP: true
            }
        },
        // add member to role.
        {
            severity: 'warning',
            title,
            description: (fields) => add_member_to_role_description(fields),
        }
    ];
}
const AddMemberToRole = {
    info: {
        title: 'User Added to Role Group',
        description: 'A User was added to a Role Group.',
    },
    description_extractor: (log) => {
        return {
            user: GetID(log, 'Target', 5),
            service_principal: GetID(log, 'Target', 2),
            roleName: GetModifiedProperties(log, 'Role.DisplayName')
        };
    },
    classifications: AddMemberToRoleClx('User Added to Role Group')
};
const AddUser = {
    info: {
        title: 'New User Created',
        description: 'A new user was created on your tenant.',
    }
};
const change_user_license_description = (fields) => {
    return `User License was changed on ${fields.user}.`;
};
const ChangeUserLicenseClx = [
    {
        severity: 'warning',
        title: 'User License Changed',
        description: (fields) => change_user_license_description(fields)
    }
];
const ChangeUserLicense = {
    info: {
        title: 'User License Changed',
        description: 'A User License has changed.'
    },
    description_extractor: (log) => {
        return {
            user: GetID(log, 'Target', 5)
        };
    },
    classifications: ChangeUserLicenseClx
};
const operations = {
    'Consent to application.': ConsentToApplication,
    'Add member to role.': AddMemberToRole,
    'Add user.': AddUser,
    'Change user license.': ChangeUserLicense
};
exports.default = operations;
