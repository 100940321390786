"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Predicates = exports.WorkloadGuard = void 0;
exports.WorkloadGuard = {
    Aip: true,
    AeD: true,
    AirInvestigation: true,
    ApplicationAudit: true,
    AttackSim: true,
    AzureActiveDirectory: true,
    Compliance: true,
    CRM: true,
    Dynamics365: true,
    Endpoint: true,
    Exchange: true,
    Kaizala: true,
    MicrosoftFlow: true,
    MicrosoftForms: true,
    MicrosoftStream: true,
    MicrosoftTeams: true,
    MyAnalytics: true,
    OneDrive: true,
    PowerApps: true,
    PowerBI: true,
    PrivacyInsights: true,
    PublicEndpoint: true,
    Quarantine: true,
    SecurityComplianceCenter: true,
    SharePoint: true,
    SkypeForBusiness: true,
    ThreatIntelligence: true,
    WorkplaceAnalytics: true,
    Yammer: true,
};
class Predicates {
}
exports.Predicates = Predicates;
