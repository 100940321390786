"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SharePoint_1 = require("../SharePoint/SharePoint");
const PageViewed = {
    info: {
        title: 'OneDrive Page Viewed',
        description: 'A page was viewed on a OneDrive site.',
    },
    extractor: (log) => {
        return {
            ObjectId: log.ObjectId
        };
    },
    classifications: (0, SharePoint_1.PageViewedClx)('OneDrive Page Viewed', 'A page was viewed on a OneDrive site.')
};
const operations = {
    'PageViewed': PageViewed
};
exports.default = operations;
