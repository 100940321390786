import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Severities } from '@octiga/microsoft-events/dist/interfaces/summary.interface';
import { Subscription } from 'rxjs';
import { filter, map, skipUntil, take } from 'rxjs/operators';
import { CardComponent } from '../card.component';
import { IconsSrc, RisksEnum } from 'src/app/modules/hardening/components/risks/model';
import { UserRisksService } from 'src/app/modules/hardening/services/user-risks.service';
import { client } from 'src/app/stores/client';
import { fetchGraphAuthenticationMethods } from 'src/app/stores/client/graph/authentication/methods/actions';
import { fetchGraphPerUserMfaState } from 'src/app/stores/client/graph/authentication/perUserMfaState/actions';
import { loadConditionalAccessPolicy } from 'src/app/stores/client/graph/conditional-access/actions';
import { loadDirectoryRoles } from 'src/app/stores/client/graph/directory-roles/actions';
import { loadGroups } from 'src/app/stores/client/graph/group/actions';
import { loadSecurityDefaultsPolicy } from 'src/app/stores/client/graph/security-defaults/actions';
import { loadGraphUsers } from 'src/app/stores/client/graph/user/user.actions';
import { fetchMailboxes } from 'src/app/stores/client/powershell/exo/mailbox/actions';
import { loadBaselines } from 'src/app/stores/client/sway/baseline/actions';
import { loadDeviations } from 'src/app/stores/client/sway/deviation/deviation.actions';
import { loadSwayGroups } from 'src/app/stores/client/sway/group/actions';
import { loadSwayTenant } from 'src/app/stores/client/sway/tenant/actions';
import { loadSpecs } from 'src/app/stores/root/sway/spec/actions';

interface RiskItem {
    type: RisksEnum;
    severity: Partial<Severities>;
}

const RISK_TYPES = Object.values(RisksEnum);

@Component({
    selector: 'app-account-risks',
    templateUrl: './account-risks.component.html',
    styleUrls: ['./account-risks.component.scss'],
})
export class AccountRisksComponent extends CardComponent implements OnInit, OnDestroy {
    public tenant_id = this.route.snapshot.paramMap.get('tenant');
    public IconsSrc = IconsSrc;
    public isLoading = true;

    // main view data
    public risks: RiskItem[] = Object.values(RisksEnum).map((type) => ({
        type,
        severity: {
            critical: -1,
            danger: -1,
            warning: -1,
            info: -1,
            total: -1,
        },
    }));

    private sub: Subscription;
    // allRiskSeverity: { critical: number; danger: number; warning: number; } = { critical: 0, danger: 0, warning: 0 };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<any>,
        private riskService: UserRisksService,
    ) {
        super();
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    ngOnInit(): void {
        this.dispatcher();
        this.getData();
    }

    dispatcher() {
        this.store.dispatch(loadDeviations({ _tenant: this.tenant_id }));
        this.store.dispatch(loadGraphUsers({ _tenant: this.tenant_id }));
        this.store.dispatch(fetchMailboxes({ _tenant: this.tenant_id }));
        this.store.dispatch(loadDirectoryRoles({ _tenant: this.tenant_id })); // , name: "Global Administrator"
        this.store.dispatch(loadBaselines({ _tenant: this.tenant_id }));
        this.store.dispatch(loadSwayGroups({ _tenant: this.tenant_id }));
        this.store.dispatch(loadConditionalAccessPolicy({ _tenant: this.tenant_id }));
        this.store.dispatch(loadGroups({ _tenant: this.tenant_id }));
        this.store.dispatch(loadSwayTenant({ _tenant: this.tenant_id }));
        this.store.dispatch(loadSecurityDefaultsPolicy({ _tenant: this.tenant_id }));

        this.loadAuthenticationMethods(this.tenant_id);
        this.store.dispatch(loadSpecs());
    }

    private loadAuthenticationMethods(tenant_id: string) {
        const users$ = this.store.select(client(tenant_id).graph.users.all);
        const usersLoaded$ = this.store.select(client(tenant_id).graph.users.status).pipe(filter((res) => res.loaded));
        users$.pipe(skipUntil(usersLoaded$), take(1)).subscribe((users) => {
            const ids = users.map((user) => user.id);
            this.store.dispatch(fetchGraphAuthenticationMethods({ _tenant: tenant_id, ids }));
            this.store.dispatch(fetchGraphPerUserMfaState({ _tenant: tenant_id, ids }));
        });
    }

    getData() {
        this.sub = this.riskService
            .getAllRisks(this.tenant_id)
            .pipe(
                map((risksExclusions) => {
                    const items: RiskItem[] = [];
                    for (const type of RISK_TYPES) {
                        const risks = risksExclusions.map((res) => ({ ...res.risk, type: res.type }));
                        // filter risks for this 'type'
                        const risks_for_type = risks.filter((r) => r.type === type);

                        // count up severities for this 'type'
                        const severity = risks_for_type.reduce(
                            (acc, item) => {
                                acc[item.level] += 1;
                                return acc;
                            },
                            { critical: 0, danger: 0, warning: 0 },
                        );

                        items.push({ type, severity });
                    }

                    return items;
                }),
            )
            .subscribe((items) => {
                this.risks = items;

                // this.allRiskSeverity = this.risks.reduce((acc, item) => {
                //     acc.critical += item.severity.critical
                //     acc.danger += item.severity.danger
                //     acc.warning += item.severity.warning
                //     return acc
                // }, { critical: 0, danger: 0, warning: 0 })

                this.isLoading = false;
            });
    }

    public browse(queryParams) {
        this.router.navigate(['client', this.tenant_id, 'hardening', 'accounts_risks'], {
            queryParams: { type: queryParams },
        });
    }

    // public navigateToUserList() {
    //     this.router.navigate(['client', this.tenant_id, 'monitoring', 'events-user'])
    // }
}
