"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileAccessedOrDownloadedClx = void 0;
const statements_1 = require("../statements");
function FileAccessedOrDownloadedClx(title, description) {
    return [
        // file accessed: fraudulent IP. not in allowed list.
        {
            severity: "danger",
            title,
            description: `${description} ${statements_1.fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: fraudulent IP.  allowed IP
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.fraud_ip_in_ip}`,
            predicates: {
                isAllowedIP: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: fraudulent IP. allowed region.
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: fraudulent IP. in allowed countries.
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: fraudulent IP. in allowed asn.
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: user in org. not in allowed list
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: true,
            },
        },
        // file accessed: user not in org. not in allowed list
        {
            severity: "info",
            title,
            description: `${description} ${statements_1.user_not_in_org} ${statements_1.not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: false,
            },
        },
        // file accessed: microsoft activity
        {
            severity: "info",
            title,
            description: `${description} ${statements_1.is_microsoft_activity}`,
            predicates: {
                isMicrosoftActivity: true,
            },
        },
        // file accessed
        {
            severity: "info",
            title,
            description: `${description}`,
        },
    ];
}
exports.FileAccessedOrDownloadedClx = FileAccessedOrDownloadedClx;
const FileAccessed = {
    info: {
        title: "SharePoint File Accessed",
        description: "A user or system account viewed a file.",
    },
    extractor: (log) => {
        return {
            ObjectId: log.ObjectId,
        };
    },
    classifications: FileAccessedOrDownloadedClx("SharePoint File Accessed", "A file was accessed on SharePoint."),
};
const FileDownloaded = {
    info: {
        title: "SharePoint File Downloaded",
        description: "A user downloaded a file.",
    },
    extractor: (log) => {
        return {
            ObjectId: log.ObjectId,
        };
    },
    classifications: FileAccessedOrDownloadedClx("SharePoint File Downloaded", "A file was downloaded from SharePoint."),
};
const operations = {
    FileAccessed: FileAccessed,
    FileDownloaded: FileDownloaded,
};
exports.default = operations;
