"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSystemUser = exports.isPointInCircle = exports.isIPPublic = exports.isIPInNetwork = void 0;
const ipaddr_js_1 = require("ipaddr.js");
/**
 * Check if an IP address is within a network range.
 *
 * @param {string} ipAddress - The IP address to check.
 * @param {string} networkAddress - The network address in CIDR notation (e.g., "192.168.1.0/24").
 * @returns {boolean} - true if the IP address is within the network range, false otherwise.
 */
function isIPInNetwork(ipAddress, networkAddress) {
    if (typeof ipAddress !== "string" || typeof networkAddress !== "string") {
        return false;
    }
    const ip = (0, ipaddr_js_1.parse)(ipAddress);
    // if rule is for network range
    if (networkAddress.includes("/")) {
        const network = (0, ipaddr_js_1.parseCIDR)(networkAddress);
        // if IP address and network are of the same kind
        if (ip.kind() === network[0].kind()) {
            return ip.match(network); // check IP in network
        }
        else {
            return false; // IP types are different
        }
    }
    else {
        // if rule is for a single IP
        return ipAddress === networkAddress;
    }
}
exports.isIPInNetwork = isIPInNetwork;
/**
 * Check if an IP address is public or reserved.
 *
 * @param {string} ipAddress - The IP address to check.
 * @returns {boolean} - true if the IP address is public, false if it is reserved.
 */
function isIPPublic(ipAddress) {
    const ip = (0, ipaddr_js_1.parse)(ipAddress);
    return ip.range() === "unicast";
}
exports.isIPPublic = isIPPublic;
/**
 * Check if a point is within a circle.
 *
 * @param {Coord} checkPoint - The point to check.
 * @param {Coord} centerPoint - The center point of the circle.
 * @param {number} radius - The radius of the circle in meters.
 * @returns {boolean} - true if the point is within the circle, false otherwise.
 */
function isPointInCircle(checkPoint, centerPoint, radius) {
    const ky = 40000 / 360;
    const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= radius / 1000;
}
exports.isPointInCircle = isPointInCircle;
/**
 * Check if a user is a system user.
 *
 * @param {number} user_type - The user type.
 * @returns {boolean} - true if the user is a system user, false otherwise.
 */
function isSystemUser(user_type) {
    // known user types
    const SystemUser = {
        0: false,
        1: false,
        2: false,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        99: true
    };
    return SystemUser[user_type] ?? false; // return false if unknown user type
}
exports.isSystemUser = isSystemUser;
