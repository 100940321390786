"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function TIMailData_description(fields) {
    return `A Malicious Mail named "${fields.Subject}" was intercepted.`;
}
const TIMailDataClx = [
    {
        severity: 'warning',
        title: 'Malicious Mail Intercepted',
        description: (fields) => TIMailData_description(fields)
    }
];
const TIMailData = {
    info: {
        title: 'Malicious Mail Intercepted',
        description: 'A Malicious Mail was intercepted.'
    },
    description_extractor: (log) => {
        return {
            P1Sender: log.P1Sender,
            P2Sender: log.P2Sender,
            SenderIp: log.SenderIp,
            Subject: log.Subject,
            Recipients: log.Recipients.join(', ')
        };
    },
    classifications: TIMailDataClx
};
const operations = {
    'TIMailData': TIMailData,
};
exports.default = operations;
