"use strict";
// https://docs.microsoft.com/en-us/office/office-365-management-api/office-365-management-activity-api-schema
// https://github.com/MicrosoftDocs/office-365-management-api/blob/master/office-365-management-api/office-365-management-activity-api-schema.md
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditLogScope = exports.UserType = exports.AuditLogRecordType = void 0;
var AuditLogRecordType;
(function (AuditLogRecordType) {
    AuditLogRecordType[AuditLogRecordType["ExchangeAdmin"] = 1] = "ExchangeAdmin";
    AuditLogRecordType[AuditLogRecordType["ExchangeItem"] = 2] = "ExchangeItem";
    AuditLogRecordType[AuditLogRecordType["ExchangeItemGroup"] = 3] = "ExchangeItemGroup";
    AuditLogRecordType[AuditLogRecordType["SharePoint"] = 4] = "SharePoint";
    AuditLogRecordType[AuditLogRecordType["SharePointFileOperation"] = 6] = "SharePointFileOperation";
    AuditLogRecordType[AuditLogRecordType["OneDrive"] = 7] = "OneDrive";
    AuditLogRecordType[AuditLogRecordType["AzureActiveDirectory"] = 8] = "AzureActiveDirectory";
    AuditLogRecordType[AuditLogRecordType["AzureActiveDirectoryAccountLogon"] = 9] = "AzureActiveDirectoryAccountLogon";
    AuditLogRecordType[AuditLogRecordType["DataCenterSecurityCmdlet"] = 10] = "DataCenterSecurityCmdlet";
    AuditLogRecordType[AuditLogRecordType["ComplianceDLPSharePoint"] = 11] = "ComplianceDLPSharePoint";
    AuditLogRecordType[AuditLogRecordType["ComplianceDLPExchange"] = 13] = "ComplianceDLPExchange";
    AuditLogRecordType[AuditLogRecordType["SharePointSharingOperation"] = 14] = "SharePointSharingOperation";
    AuditLogRecordType[AuditLogRecordType["AzureActiveDirectoryStsLogon"] = 15] = "AzureActiveDirectoryStsLogon";
    AuditLogRecordType[AuditLogRecordType["SkypeForBusinessPSTNUsage"] = 16] = "SkypeForBusinessPSTNUsage";
    AuditLogRecordType[AuditLogRecordType["SkypeForBusinessUsersBlocked"] = 17] = "SkypeForBusinessUsersBlocked";
    AuditLogRecordType[AuditLogRecordType["SecurityComplianceCenterEOPCmdlet"] = 18] = "SecurityComplianceCenterEOPCmdlet";
    AuditLogRecordType[AuditLogRecordType["ExchangeAggregatedOperation"] = 19] = "ExchangeAggregatedOperation";
    AuditLogRecordType[AuditLogRecordType["PowerBIAudit"] = 20] = "PowerBIAudit";
    AuditLogRecordType[AuditLogRecordType["CRM"] = 21] = "CRM";
    AuditLogRecordType[AuditLogRecordType["Yammer"] = 22] = "Yammer";
    AuditLogRecordType[AuditLogRecordType["SkypeForBusinessCmdlets"] = 23] = "SkypeForBusinessCmdlets";
    AuditLogRecordType[AuditLogRecordType["Discovery"] = 24] = "Discovery";
    AuditLogRecordType[AuditLogRecordType["MicrosoftTeams"] = 25] = "MicrosoftTeams";
    AuditLogRecordType[AuditLogRecordType["ThreatIntelligence"] = 28] = "ThreatIntelligence";
    AuditLogRecordType[AuditLogRecordType["MailSubmission"] = 29] = "MailSubmission";
    AuditLogRecordType[AuditLogRecordType["MicrosoftFlow"] = 30] = "MicrosoftFlow";
    AuditLogRecordType[AuditLogRecordType["AeD"] = 31] = "AeD";
    AuditLogRecordType[AuditLogRecordType["MicrosoftStream"] = 32] = "MicrosoftStream";
    AuditLogRecordType[AuditLogRecordType["ComplianceDLPSharePointClassification"] = 33] = "ComplianceDLPSharePointClassification";
    AuditLogRecordType[AuditLogRecordType["ThreatFinder"] = 34] = "ThreatFinder";
    AuditLogRecordType[AuditLogRecordType["Project"] = 35] = "Project";
    AuditLogRecordType[AuditLogRecordType["SharePointListOperation"] = 36] = "SharePointListOperation";
    AuditLogRecordType[AuditLogRecordType["SharePointCommentOperation"] = 37] = "SharePointCommentOperation";
    AuditLogRecordType[AuditLogRecordType["DataGovernance"] = 38] = "DataGovernance";
    AuditLogRecordType[AuditLogRecordType["Kaizala"] = 39] = "Kaizala";
    AuditLogRecordType[AuditLogRecordType["SecurityComplianceAlerts"] = 40] = "SecurityComplianceAlerts";
    AuditLogRecordType[AuditLogRecordType["ThreatIntelligenceUrl"] = 41] = "ThreatIntelligenceUrl";
    AuditLogRecordType[AuditLogRecordType["SecurityComplianceInsights"] = 42] = "SecurityComplianceInsights";
    AuditLogRecordType[AuditLogRecordType["MIPLabel"] = 43] = "MIPLabel";
    AuditLogRecordType[AuditLogRecordType["WorkplaceAnalytics"] = 44] = "WorkplaceAnalytics";
    AuditLogRecordType[AuditLogRecordType["PowerAppsApp"] = 45] = "PowerAppsApp";
    AuditLogRecordType[AuditLogRecordType["PowerAppsPlan"] = 46] = "PowerAppsPlan";
    AuditLogRecordType[AuditLogRecordType["ThreatIntelligenceAtpContent"] = 47] = "ThreatIntelligenceAtpContent";
    AuditLogRecordType[AuditLogRecordType["LabelContentExplorer"] = 48] = "LabelContentExplorer";
    AuditLogRecordType[AuditLogRecordType["TeamsHealthcare"] = 49] = "TeamsHealthcare";
    AuditLogRecordType[AuditLogRecordType["ExchangeItemAggregated"] = 50] = "ExchangeItemAggregated";
    AuditLogRecordType[AuditLogRecordType["HygieneEvent"] = 51] = "HygieneEvent";
    AuditLogRecordType[AuditLogRecordType["DataInsightsRestApiAudit"] = 52] = "DataInsightsRestApiAudit";
    AuditLogRecordType[AuditLogRecordType["InformationBarrierPolicyApplication"] = 53] = "InformationBarrierPolicyApplication";
    AuditLogRecordType[AuditLogRecordType["SharePointListItemOperation"] = 54] = "SharePointListItemOperation";
    AuditLogRecordType[AuditLogRecordType["SharePointContentTypeOperation"] = 55] = "SharePointContentTypeOperation";
    AuditLogRecordType[AuditLogRecordType["SharePointFieldOperation"] = 56] = "SharePointFieldOperation";
    AuditLogRecordType[AuditLogRecordType["MicrosoftTeamsAdmin"] = 57] = "MicrosoftTeamsAdmin";
    AuditLogRecordType[AuditLogRecordType["HRSignal"] = 58] = "HRSignal";
    AuditLogRecordType[AuditLogRecordType["MicrosoftTeamsDevice"] = 59] = "MicrosoftTeamsDevice";
    AuditLogRecordType[AuditLogRecordType["MicrosoftTeamsAnalytics"] = 60] = "MicrosoftTeamsAnalytics";
    AuditLogRecordType[AuditLogRecordType["InformationWorkerProtection"] = 61] = "InformationWorkerProtection";
    AuditLogRecordType[AuditLogRecordType["Campaign"] = 62] = "Campaign";
    AuditLogRecordType[AuditLogRecordType["DLPEndpoint"] = 63] = "DLPEndpoint";
    AuditLogRecordType[AuditLogRecordType["AirInvestigation"] = 64] = "AirInvestigation";
    AuditLogRecordType[AuditLogRecordType["Quarantine"] = 65] = "Quarantine";
    AuditLogRecordType[AuditLogRecordType["MicrosoftForms"] = 66] = "MicrosoftForms";
    AuditLogRecordType[AuditLogRecordType["ApplicationAudit"] = 67] = "ApplicationAudit";
    AuditLogRecordType[AuditLogRecordType["ComplianceSupervisionExchange"] = 68] = "ComplianceSupervisionExchange";
    AuditLogRecordType[AuditLogRecordType["CustomerKeyServiceEncryption"] = 69] = "CustomerKeyServiceEncryption";
    AuditLogRecordType[AuditLogRecordType["OfficeNative"] = 70] = "OfficeNative";
    AuditLogRecordType[AuditLogRecordType["MipAutoLabelSharePointItem"] = 71] = "MipAutoLabelSharePointItem";
    AuditLogRecordType[AuditLogRecordType["MipAutoLabelSharePointPolicyLocation"] = 72] = "MipAutoLabelSharePointPolicyLocation";
    AuditLogRecordType[AuditLogRecordType["MicrosoftTeamsShifts"] = 73] = "MicrosoftTeamsShifts";
    AuditLogRecordType[AuditLogRecordType["MipAutoLabelExchangeItem"] = 75] = "MipAutoLabelExchangeItem";
    AuditLogRecordType[AuditLogRecordType["CortanaBriefing"] = 76] = "CortanaBriefing";
    AuditLogRecordType[AuditLogRecordType["Search"] = 77] = "Search";
    AuditLogRecordType[AuditLogRecordType["WDATPAlerts"] = 78] = "WDATPAlerts";
    AuditLogRecordType[AuditLogRecordType["MDATPAudit"] = 81] = "MDATPAudit";
    AuditLogRecordType[AuditLogRecordType["SensitivityLabelPolicyMatch"] = 82] = "SensitivityLabelPolicyMatch";
    AuditLogRecordType[AuditLogRecordType["SensitivityLabelAction"] = 83] = "SensitivityLabelAction";
    AuditLogRecordType[AuditLogRecordType["SensitivityLabeledFileAction"] = 84] = "SensitivityLabeledFileAction";
    AuditLogRecordType[AuditLogRecordType["AttackSim"] = 85] = "AttackSim";
    AuditLogRecordType[AuditLogRecordType["AirManualInvestigation"] = 86] = "AirManualInvestigation";
    AuditLogRecordType[AuditLogRecordType["SecurityComplianceRBAC"] = 87] = "SecurityComplianceRBAC";
    AuditLogRecordType[AuditLogRecordType["UserTraining"] = 88] = "UserTraining";
    AuditLogRecordType[AuditLogRecordType["AirAdminActionInvestigation"] = 89] = "AirAdminActionInvestigation";
    AuditLogRecordType[AuditLogRecordType["MSTIC"] = 90] = "MSTIC";
    AuditLogRecordType[AuditLogRecordType["PhysicalBadgingSignal"] = 91] = "PhysicalBadgingSignal";
    AuditLogRecordType[AuditLogRecordType["AipDiscover"] = 93] = "AipDiscover";
    AuditLogRecordType[AuditLogRecordType["AipSensitivityLabelAction"] = 94] = "AipSensitivityLabelAction";
    AuditLogRecordType[AuditLogRecordType["AipProtectionAction"] = 95] = "AipProtectionAction";
    AuditLogRecordType[AuditLogRecordType["AipFileDeleted"] = 96] = "AipFileDeleted";
    AuditLogRecordType[AuditLogRecordType["AipHeartBeat"] = 97] = "AipHeartBeat";
    AuditLogRecordType[AuditLogRecordType["MCASAlerts"] = 98] = "MCASAlerts";
    AuditLogRecordType[AuditLogRecordType["OnPremisesFileShareScannerDlp"] = 99] = "OnPremisesFileShareScannerDlp";
    AuditLogRecordType[AuditLogRecordType["OnPremisesSharePointScannerDlp"] = 100] = "OnPremisesSharePointScannerDlp";
    AuditLogRecordType[AuditLogRecordType["ExchangeSearch"] = 101] = "ExchangeSearch";
    AuditLogRecordType[AuditLogRecordType["SharePointSearch"] = 102] = "SharePointSearch";
    AuditLogRecordType[AuditLogRecordType["PrivacyInsights"] = 103] = "PrivacyInsights";
    AuditLogRecordType[AuditLogRecordType["MyAnalyticsSettings"] = 105] = "MyAnalyticsSettings";
    AuditLogRecordType[AuditLogRecordType["SecurityComplianceUserChange"] = 106] = "SecurityComplianceUserChange";
    AuditLogRecordType[AuditLogRecordType["ComplianceDLPExchangeClassification"] = 107] = "ComplianceDLPExchangeClassification";
    AuditLogRecordType[AuditLogRecordType["MipExactDataMatch"] = 109] = "MipExactDataMatch";
})(AuditLogRecordType = exports.AuditLogRecordType || (exports.AuditLogRecordType = {}));
var UserType;
(function (UserType) {
    UserType[UserType["Regular"] = 0] = "Regular";
    UserType[UserType["Reserved"] = 1] = "Reserved";
    UserType[UserType["Admin"] = 2] = "Admin";
    UserType[UserType["DcAdmin"] = 3] = "DcAdmin";
    UserType[UserType["System"] = 4] = "System";
    UserType[UserType["Application"] = 5] = "Application";
    UserType[UserType["ServicePrincipal"] = 6] = "ServicePrincipal";
    UserType[UserType["CustomPolicy"] = 7] = "CustomPolicy";
    UserType[UserType["SystemPolicy"] = 8] = "SystemPolicy";
    UserType[UserType["Octiga"] = 99] = "Octiga";
})(UserType = exports.UserType || (exports.UserType = {}));
var AuditLogScope;
(function (AuditLogScope) {
    AuditLogScope[AuditLogScope["Online"] = 0] = "Online";
    AuditLogScope[AuditLogScope["Onprem"] = 1] = "Onprem";
})(AuditLogScope = exports.AuditLogScope || (exports.AuditLogScope = {}));
