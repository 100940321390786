"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const statements_1 = require("../statements");
const risky_words = ['invoice', 'bank detail', 'payment detail', 'account detail', 'credit card', 'billing', 'iban'];
const fraud_ip = 'It originated from a fraudulent IP.';
function MailboxOperations(log) {
    return {
        login_type: log.InternalLogonType,
        internal_login_type: log.LogonType,
        result: log.OperationResult,
        external_access: log.ExternalAccess,
        owner: log.MailboxOwnerUPN,
        client: log.ClientInfoString,
        risky_keyword: !!log.AffectedItems && !!log.AffectedItems[0]['Subject'] ? risky_words.some(word => log.AffectedItems[0]['Subject'].toLowerCase().includes(word)) : false
    };
}
function MailEventClx(title, description) {
    return [
        // fraudulent and not in allowed list
        {
            severity: 'critical',
            title,
            description: `${description} ${statements_1.fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false,
                isFraudulentIP: true,
                isMicrosoftActivity: false
            }
        },
        // not in allowed list.
        {
            severity: 'danger',
            title,
            description: `${description} ${statements_1.not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false,
                isMicrosoftActivity: false
            }
        },
        // fraudulent IP.
        {
            severity: 'warning',
            title,
            description: `${description} ${fraud_ip}`,
            predicates: {
                isFraudulentIP: true
            }
        },
        {
            severity: 'info',
            title,
            description: `${description}`,
            predicates: {
                isMicrosoftActivity: true
            }
        },
        {
            severity: 'info',
            title,
            description: `${description}`
        }
    ];
}
const Create = {
    info: {
        title: 'Mail Items Created',
        description: 'An item was created in the Calendar, Contacts, Notes, or Tasks folder in the mailbox.',
    },
    extractor: MailboxOperations,
    description_extractor: (log) => {
        let item;
        if (log.Item && log.Item.Subject) { // ual
            item = log.Item.Subject;
        }
        else if (log.ItemSubject) { // mal
            item = log.ItemSubject;
        }
        let folder;
        if (log.Item && log.Item.ParentFolder && log.Item.ParentFolder.Path) { // ual
            folder = log.Item.ParentFolder.Path;
        }
        else if (log.FolderPathName) { // mal
            folder = log.FolderPathName;
        }
        return { item, folder };
    },
    classifications: [{
            severity: 'info',
            title: 'Mail Items Created',
            description: (fields) => {
                if (!!fields.item) {
                    return `An item named "${fields.item}" was created in "${fields.folder}" folder.`;
                }
                else {
                    return `An item was created in the "${fields.folder}" folder.`;
                }
            }
        }]
};
const UpdateInboxRules = {
    info: {
        title: 'Inbox Rules Updated.',
        description: 'A mailbox owner or other user with access to the mailbox modified an inbox rule in the Outlook client.',
    },
    extractor: MailboxOperations,
    classifications: MailEventClx('Inbox Rules Updated', 'An inbox rule was updated.')
};
const MailboxLogin = {
    info: {
        title: 'Mailbox Login',
        description: 'User signed into the mailbox.',
    },
    extractor: MailboxOperations,
    classifications: MailEventClx('Mailbox Login', 'The user signed into their mailbox.')
};
const FolderBind = {
    info: {
        title: 'Mailbox Folder Accessed',
        description: 'A mailbox folder was accessed.',
    }
};
const MailItemsAccessed = {
    info: {
        title: 'Mail Items Accessed',
        description: 'Messages were read or accessed in the mailbox.',
    }
};
const HardDelete = {
    info: {
        title: 'Message Purged',
        description: 'A message was purged from the Recoverable Items folder (permanently deleted).',
    }
};
const Move = {
    info: {
        title: 'Message Moved',
        description: 'A message was moved to another folder.',
    }
};
const Send = {
    info: {
        title: 'Message Sent',
        description: 'A message was sent.',
    }
};
const SendAs = {
    info: {
        title: 'Message Sent Using SendAs',
        description: 'A message was sent as if it came from the mailbox owner.',
    }
};
const Update = {
    info: {
        title: 'Message Updated',
        description: 'A message or its properties were changed.',
    }
};
const ApplyRecord = {
    info: {
        title: 'Apply Record',
        description: 'An item is labeled as a record.',
    }
};
const Copy = {
    info: {
        title: 'Apply Record',
        description: 'A message was copied to another folder.',
    }
};
const MessageBind = {
    info: {
        title: 'Message Bind',
        description: 'A message was viewed in the preview pane or opened by an admin (E3 users only).',
    }
};
const MoveToDeletedItems = {
    info: {
        title: 'Move To Deleted Items',
        description: 'A message was deleted and moved to the Deleted Items folder.',
    }
};
const RecordDelete = {
    info: {
        title: 'Record Deleted',
        description: 'An item that\'s labeled as a record was soft-deleted (moved to the Recoverable Items folder). Items labeled as records can\'t be permanently deleted.',
    }
};
const SearchQueryInitiated = {
    info: {
        title: 'Search Query Initiated',
        description: 'User performed an Advanced Audit search in Exchange Online (E5 or E5 Compliance add-on subscription users only).',
    }
};
const SendOnBehalf = {
    info: {
        title: 'Send On Behalf Of',
        description: 'An item that\'s labeled as a record was soft-deleted (moved to the Recoverable Items folder). Items labeled as records can\'t be permanently deleted.',
    }
};
const SoftDelete = {
    info: {
        title: 'Soft Delete',
        description: 'A message was permanently deleted or deleted from the Deleted Items folder. Soft-deleted items are moved to the Recoverable Items folder.',
    }
};
const UpdateCalendarDelegation = {
    info: {
        title: 'Update Calendar Delegation',
        description: 'A calendar delegation was assigned to a mailbox. Calendar delegation gives someone else in the same organization permissions to manage the mailbox owner\'s calendar.',
    }
};
const UpdateComplianceTag = {
    info: {
        title: 'Update Compliance Tag',
        description: 'A different retention label was applied to a mail item (an item can only have one retention label assigned to it).',
    }
};
const UpdateFolderPermissions = {
    info: {
        title: 'Update Folder Permissions',
        description: 'A folder permission was changed. Folder permissions control which users in your organization can access folders in a mailbox and the messages located in those folders.',
    }
};
const operations = {
    Create,
    FolderBind,
    HardDelete,
    MailboxLogin,
    MailItemsAccessed,
    Move,
    Send,
    SendAs,
    Update,
    UpdateInboxRules,
    ApplyRecord,
    Copy,
    MessageBind,
    MoveToDeletedItems,
    RecordDelete,
    SearchQueryInitiated,
    SendOnBehalf,
    SoftDelete,
    UpdateCalendarDelegation,
    UpdateComplianceTag,
    UpdateFolderPermissions // check location
};
exports.default = operations;
