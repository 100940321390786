"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteCollectionAdminAddedClx = void 0;
const statements_1 = require("../statements");
function SiteCollectionAdminAddedClx(title, description) {
    return [
        // site admin added: fraudulent IP. not in allowed list.
        {
            severity: "danger",
            title,
            description: `${description} ${statements_1.fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: fraudulent IP. allowed IP.
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.fraud_ip_in_ip}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedIP: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: fraudulent IP. allowed region.
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.fraud_ip_in_region}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedRegion: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: fraudulent IP. in allowed countries
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.fraud_ip_in_country}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedCountry: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: fraudulent IP. in allowed asn
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.fraud_ip_in_asn}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedASN: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: not in allowed list.
        {
            severity: "warning",
            title,
            description: `${description} ${statements_1.not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: microsoft activity
        {
            severity: "info",
            title,
            description: `${description} ${statements_1.is_microsoft_activity}`,
            predicates: {
                isMicrosoftActivity: true,
            },
        },
        // site admin added
        {
            severity: "info",
            title,
            description: `${description}`,
        },
    ];
}
exports.SiteCollectionAdminAddedClx = SiteCollectionAdminAddedClx;
const SiteCollectionAdminAdded = {
    info: {
        title: "SharePoint Site Collection Admin Added",
        description: "A person was added as a site collection administrator. Site collection administrators have full permissions for the site collection and all subsites.",
    },
    extractor: (log) => {
        return {
            ObjectId: log.ObjectId,
        };
    },
    classifications: SiteCollectionAdminAddedClx("SharePoint Site Collection Admin Added", "A person was added as a site collection administrator."),
};
const operations = {
    SiteCollectionAdminAdded: SiteCollectionAdminAdded,
};
exports.default = operations;
