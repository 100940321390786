"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function TIUrlClickData_description(fields) {
    return `A Malicious Url "${fields.Url}" was clicked in a mail.`;
}
const TIUrlClickDataClx = [
    {
        severity: 'critical',
        title: 'Malicious URL Clicked',
        description: (fields) => TIUrlClickData_description(fields)
    }
];
const TIUrlClickData = {
    info: {
        title: 'Malicious URL Clicked',
        description: 'A Malicious URL was clicked.'
    },
    description_extractor: (log) => {
        return {
            Url: log.Url
        };
    },
    classifications: TIUrlClickDataClx
};
const operations = {
    'TIUrlClickData': TIUrlClickData
};
exports.default = operations;
