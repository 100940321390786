import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as actions from './actions';
import { MicrosoftEvent } from '@octiga/microsoft-events/dist/interfaces/meta.interface';
import { Status, initialStatus } from 'src/app/stores/status.interface';

export const featureKey = 'events';

export interface State extends EntityState<MicrosoftEvent>, Status {
    cursor: string;
    loadingDeltas: Set<string>;
    loadedDates: Set<string>;
    LastEvaluatedKeys: {
        [key: string]: any | null;
    };
    UserLastEvaluatedKeys: {
        [user: string]: {
            [key: string]: any | null;
        };
    };
}

export const adapter: EntityAdapter<MicrosoftEvent> = createEntityAdapter<MicrosoftEvent>({
    sortComparer: (a, b) => b.timestamp.localeCompare(a.timestamp),
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
    cursor: new Date().toISOString().split('T')[0],
    loadingDeltas: new Set<string>(),
    loadedDates: new Set<string>(),
    UserLastEvaluatedKeys: {},
    LastEvaluatedKeys: {},
});

const setUserLastEvaluatedKey = (state: State, action: actions.IfetchEventsByUserSuccess): State => {
    const userKeys = { ...state.UserLastEvaluatedKeys };
    if (!!userKeys[action.user_id]) {
        userKeys[action.user_id][action.date] = action.LastEvaluatedKey ? action.LastEvaluatedKey : null;
    } else {
        userKeys[action.user_id] = {
            [action.date]: action.LastEvaluatedKey ? action.LastEvaluatedKey : null,
        };
    }

    return {
        ...state,
        UserLastEvaluatedKeys: userKeys,
    };
};

function fetchEventsDelta(state: State, action: actions.IfetchEventsDelta) {
    const date = action.start.split('T')[0];
    if (state.loadedDates.has(date) || state.loadingDeltas.has(action.start)) {
        return state;
    }
    const loadingDeltas = new Set([...state.loadingDeltas, action.start]);
    return {
        ...state,
        loadingDeltas,
    };
}

function fetchEventsDeltaSuccess(state: State, action: actions.IfetchEventsDeltaSuccess): State {
    const entities = {
        ...state.entities,
        ...action.events.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
    };

    const ids = Object.keys(entities).sort((a, b) => entities[b].timestamp.localeCompare(entities[a].timestamp));

    const LastEvaluatedKeys = {
        ...state.LastEvaluatedKeys,
        [action.start]: action.LastEvaluatedKey ? action.LastEvaluatedKey : null,
    };

    const date = action.start.split('T')[0];

    const date_loaded = Object.keys(LastEvaluatedKeys)
        .filter((key) => key.includes(date))
        .every((key) => LastEvaluatedKeys[key] === null);

    let loadedDates = state.loadedDates;
    let loadingDeltas = state.loadingDeltas;

    if (date_loaded) {
        loadedDates = new Set([...state.loadedDates, date]);
        loadingDeltas.delete(action.start);
        loadingDeltas = new Set([...loadingDeltas]); // new pointer
    }

    return {
        ...state,
        ids,
        entities,
        LastEvaluatedKeys,
        loadedDates,
        loadingDeltas,
    };
}

export const reducer = createReducer(
    initialState,
    on(actions.fetchEventSuccess, (state, action) => adapter.upsertOne(action.event, state)),
    on(actions.fetchEventsByIds, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(actions.fetchEventsByIdsSuccess, (state, action) =>
        adapter.upsertMany(action.events, {
            ...state,
            loading: false,
            loaded: true,
        }),
    ),
    on(actions.fetchEventsByUserSuccess, (state, action) =>
        adapter.upsertMany(action.events, setUserLastEvaluatedKey(state, action)),
    ),
    on(actions.updateEventsCursor, (state, action) => ({
        ...state,
        cursor: action.date.includes('T') ? action.date.split('T')[0] : action.date,
    })),
    on(actions.fetchEventsDelta, (state, action) => fetchEventsDelta(state, action)),
    on(actions.fetchEventsDeltaSuccess, (state, action) => fetchEventsDeltaSuccess(state, action)),
);

export const selectLastEvaluatedKeys = (state: State) => state.LastEvaluatedKeys;
export const selectUserLastEvaluatedKeys = (state: State) => state.UserLastEvaluatedKeys;
export const selectEventsCursor = (state: State) => state.cursor;
export const selectDateLoaded = (state: State) => state.loadedDates;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
