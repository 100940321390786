"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SharePointFileOperation_1 = require("../SharePoint/SharePointFileOperation");
const FileAccessed = {
    info: {
        title: 'OneDrive File Accessed',
        description: 'A user or system account viewed a file.',
    },
    extractor: (log) => {
        return {
            ObjectId: log.ObjectId
        };
    },
    classifications: (0, SharePointFileOperation_1.FileAccessedOrDownloadedClx)('OneDrive File Accessed', 'A file was accessed on OneDrive.')
};
const FileDownloaded = {
    info: {
        title: 'OneDrive File Downloaded',
        description: 'A user downloaded a file.',
    },
    extractor: (log) => {
        return {
            ObjectId: log.ObjectId
        };
    },
    classifications: (0, SharePointFileOperation_1.FileAccessedOrDownloadedClx)('OneDrive File Downloaded', 'A file was downloaded from OneDrive.')
};
const operations = {
    'FileAccessed': FileAccessed,
    'FileDownloaded': FileDownloaded
};
exports.default = operations;
