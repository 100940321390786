import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { DateTime } from 'luxon';
import { MicrosoftEvent } from '@octiga/microsoft-events/dist/interfaces/meta.interface';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { concatMap, expand, filter, map, mergeMap, reduce } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { client } from '../..';
import {
    fetchEvent,
    fetchEventSuccess,
    fetchEvents,
    fetchEventsByIds,
    fetchEventsByIdsSuccess,
    fetchEventsByUser,
    fetchEventsByUserSuccess,
    fetchEventsDelta,
    fetchEventsDeltaSuccess,
} from './actions';

function createPagedEventQuery(start: string, end: string, LastEvaluatedKey?: any) {
    const key = LastEvaluatedKey ? `&LastEvaluatedKey=${encodeURIComponent(JSON.stringify(LastEvaluatedKey))}` : '';
    return `/api/microsoft/monitoring/event?start=${start}&end=${end}${key}`;
}

function createUserPagedEventQuery(action: { user_id: string; date: string; LastEvaluatedKey?: any }) {
    const key = action.LastEvaluatedKey
        ? `&LastEvaluatedKey=${encodeURIComponent(JSON.stringify(action.LastEvaluatedKey))}`
        : '';
    return `/api/microsoft/monitoring/event?user_id=${encodeURIComponent(action.user_id)}&date=${action.date}${key}`;
}

interface EventsQueryResponse {
    Count: number;
    Items: MicrosoftEvent[];
    LastEvaluatedKey?: any;
    ScannedCount: number;
}

function GenerateDeltaActions(_tenant: string, date: string, delta: 1 | 2 | 4 | 6 | 8 | 12 | 24 = 4) {
    const base = DateTime.fromISO(date, { zone: 'UTC' });
    const count = 24 / delta;
    const actions: TypedAction<'[ETL/Events] Fetch Delta'>[] = [];

    for (let index = 0; index < count; index++) {
        const start_hour = delta * index;
        const end_hour = delta * index + delta;
        const start = base.plus({ hours: start_hour }).toISO();
        const end = base.plus({ hours: end_hour }).toISO();
        actions.push(fetchEventsDelta({ _tenant, start, end }));
    }

    return actions;
}

@Injectable()
export class EventEffects {
    loadEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchEvents),
            concatMap(({ _tenant, date }) => GenerateDeltaActions(_tenant, date)),
        ),
    );

    loadEventsByIds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchEventsByIds),
            mergeMap(({ _tenant, ids }) =>
                this.fetchEventsByIds(_tenant, ids).pipe(map((events) => fetchEventsByIdsSuccess({ _tenant, events }))),
            ),
        ),
    );

    loadDeltas$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchEventsDelta),

            // check if:
            //     - user has changed cursor
            //     - still paging through data
            concatLatestFrom(({ _tenant, start }) =>
                combineLatest([
                    this.store.pipe(select(client(_tenant).events.cursor)),
                    this.store.pipe(select(client(_tenant).events.lastEvaluatedKeys(start))),
                ]),
            ),

            filter(([action, [cursor, lek]]) => {
                const same_date = action.start.includes(cursor);
                const data_missing = lek !== null; // paging finished when LastEvaluatedKey set to null
                return same_date && data_missing;
            }),

            map(([action, [cursor, LastEvaluatedKey]]) => {
                // update action with LastEvaluatedKey from previous call (undefined if first call)
                return fetchEventsDelta({ ...action, LastEvaluatedKey });
            }),

            mergeMap(({ _tenant, start, end, LastEvaluatedKey }) =>
                this.ajax.get<EventsQueryResponse>(_tenant, createPagedEventQuery(start, end, LastEvaluatedKey)).pipe(
                    concatMap(({ Items, LastEvaluatedKey }) => {
                        const actions: TypedAction<any>[] = [
                            fetchEventsDeltaSuccess({
                                _tenant,
                                start,
                                end,
                                events: Items,
                                LastEvaluatedKey,
                            }),
                        ];

                        if (LastEvaluatedKey) {
                            // continue paging
                            actions.push(fetchEventsDelta({ _tenant, start, end }));
                        }

                        return actions;
                    }),
                ),
            ),
        ),
    );

    loadUserEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchEventsByUser),
            concatLatestFrom(({ _tenant, user_id, date }) =>
                combineLatest([
                    this.store.pipe(select(client(_tenant).events.cursor)),
                    this.store.pipe(select(client(_tenant).events.userLastEvaluatedKeys(user_id, date))),
                ]),
            ),
            filter(([action, [cursor, lek]]) => {
                const same_date = action.date === cursor;
                const data_missing = lek !== null; // paging finished when LastEvaluatedKey set to null
                return same_date && data_missing;
            }),
            map(([action, [cursor, LastEvaluatedKey]]) => {
                // update action with LastEvaluatedKey from previous call (undefined if first call)
                return fetchEventsByUser({ ...action, LastEvaluatedKey });
            }),
            mergeMap((action) =>
                this.ajax.get(action._tenant, createUserPagedEventQuery(action)).pipe(
                    concatMap((data) => {
                        const actions: TypedAction<any>[] = [
                            fetchEventsByUserSuccess({
                                _tenant: action._tenant,
                                events: data.Items,
                                date: action.date,
                                user_id: action.user_id,
                                LastEvaluatedKey: data.LastEvaluatedKey,
                            }),
                        ];

                        if (data.LastEvaluatedKey) {
                            // continue paging
                            actions.push(
                                fetchEventsByUser({
                                    _tenant: action._tenant,
                                    date: action.date,
                                    user_id: action.user_id,
                                    LastEvaluatedKey: data.LastEvaluatedKey,
                                }),
                            );
                        }

                        return actions;
                    }),
                ),
            ),
        ),
    );

    loadEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchEvent),
            mergeMap((action) =>
                this.ajax.get(action._tenant, `/api/microsoft/monitoring/event/${action.id}`).pipe(
                    map((data) =>
                        fetchEventSuccess({
                            _tenant: action._tenant,
                            event: data.Item,
                        }),
                    ),
                ),
            ),
        ),
    );

    fetchEventsByIds(_tenant: string, ids: string[]): Observable<MicrosoftEvent[]> {
        const unique_ids = [...new Set(ids)];
        const request_batches: string[][] = []; // batching is limited to 100 items per batch

        let count = 0;

        do {
            const start = count * 100;
            const end = start + 100;
            request_batches.push(unique_ids.slice(start, end));
        } while (++count * 100 < unique_ids.length);

        let current_batches = 0;

        return this.ajax.post(_tenant, '/api/microsoft/monitoring/events', request_batches[current_batches]).pipe(
            expand((_) => {
                current_batches++;
                if (current_batches < request_batches.length) {
                    return this.ajax.post(
                        _tenant,
                        '/api/microsoft/monitoring/events',
                        request_batches[current_batches],
                    );
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, data: MicrosoftEvent[]) => acc.concat(data), []),
        );
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store,
    ) {}
}
