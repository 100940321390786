"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SharePointSharingOperation_1 = require("../SharePoint/SharePointSharingOperation");
const SiteCollectionAdminAdded = {
    info: {
        title: 'OneDrive Site Collection Admin Added',
        description: 'A person was added as a site collection administrator. Site collection administrators have full permissions for the site collection and all subsites.',
    },
    extractor: (log) => {
        return {
            ObjectId: log.ObjectId
        };
    },
    classifications: (0, SharePointSharingOperation_1.SiteCollectionAdminAddedClx)('OneDrive Site Collection Admin Added', 'A person was added as a site collection administrator.')
};
const operations = {
    'SiteCollectionAdminAdded': SiteCollectionAdminAdded
};
exports.default = operations;
