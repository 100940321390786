import { createAction, props } from '@ngrx/store';
import { Summary } from '@octiga/microsoft-events/dist/interfaces/summary.interface';

export const loadSummarys = createAction(
    '[Root/Summary/API] Load Summarys',
    props<{ _tenant: string; num_days: number }>(),
);

export const loadSummariesByStartEnd = createAction(
    '[Root/Summary/API] Load Summarys By Start End',
    props<{ _tenant: string; start: string; end: string }>(),
);

export const upsertSummarys = createAction(
    '[Root/Summary/API] Upsert Summarys',
    props<{ _tenant: string; summarys: Summary[]; num_days?: number }>(),
);

export interface RecalculateRequest {
    start: string;
    end: string;
}

export const recalculateSummarys = createAction(
    '[Root/Summary/API] Recalculate Summarys',
    props<{ _tenant: string; dates: RecalculateRequest[] }>(),
);

export const recalculateSummarysSuccess = createAction(
    '[Root/Summary/API] Recalculate Summarys Success',
    props<{ _tenant: string }>(),
);

export const recalculateSummarysFailure = createAction(
    '[Root/Summary/API] Recalculate Summarys Failure',
    props<{ _tenant: string; error: any }>(),
);
